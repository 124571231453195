<template>
<v-container>
    <v-row>
      <v-col cols="12" class="text-center">
          <header-vue/>
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
        md="12"
      >
            <v-card>
                <v-card-title class="red--text">
                    TERMS
                </v-card-title>
                <v-card-subtitle>
                    Manage CURRENT Term, Past Terms and New Sesions
                </v-card-subtitle>
                <v-card-text>
                    <v-row>
                        <!-- Create /Update Form -->
                        <v-col cols="12" v-if="newform">
                            <v-form v-model="valid" ref="form">
                                <v-container>
                                    <v-row>
                                        <v-col
                                        cols="12"
                                        md="4"
                                        >
                                            <v-select
                                                v-model="form.session_id"
                                                outlined
                                                :items="sessions"
                                                item-text="name"
                                                item-value="session_id"
                                                :rules="[v => !!v || 'Session is required']"
                                                label="Select Session"
                                                required
                                            ></v-select>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="4"
                                        >
                                            <v-select
                                                v-model="form.term"
                                                outlined
                                                :items="terms"
                                                item-text="name"
                                                item-value="id"
                                                :rules="[v => !!v || 'Term is required']"
                                                label="Select Current Term"
                                                required
                                            ></v-select>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="4"
                                        >
                                            <v-menu
                                                v-model="menu2"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="form.resumptiondate"
                                                        outlined
                                                        label="Select Next Term Resumption"
                                                        append-icon="mdi-calendar"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                v-model="form.resumptiondate"
                                                @input="menu2 = false"
                                                ></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="4"
                                        >
                                            <v-text-field
                                                outlined
                                                v-model="form.schooldays"
                                                label="Number of days in term"
                                                type="number"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="8"
                                        >
                                            <v-textarea
                                            outlined
                                            rows="1"
                                            row-height="15"
                                            v-model="form.generalupdate"
                                            label="Info for Parents / General Update"
                                            ></v-textarea>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="4"
                                        v-if="editBtn"
                                        >
                                            <v-checkbox
                                            v-model="form.current"
                                            label="Tick to make current term"
                                            required
                                            ></v-checkbox>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="12"
                                        >
                                        <v-btn :disabled="!valid" color="success"
                                        class="mr-4" @click="postRecord" v-if="!editBtn"
                                        >
                                            Create
                                        </v-btn>
                                        <v-btn :disabled="!valid" color="success"
                                        class="mr-4" @click="updateRecord" v-else
                                        >
                                            Update
                                        </v-btn>
                                        <v-btn tile text color="secondary" @click="newform = false">
                                            <v-icon left>mdi-back</v-icon>
                                            Go Back
                                        </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-col>
                        <!-- Records -->
                        <v-col cols="12" v-else>
                            <v-alert
                                type="info"
                                dense
                                border="left"
                                text
                                color="orange"
                                >
                                <marquee>
                                    <span v-for="(info,i) in infos" :key="i" class="black--text mr-5">
                                    {{info.content}} | &nbsp;
                                    </span>
                                </marquee>
                            </v-alert>
                            <info-loader-2 v-if="fetchingContent"/>
                            <v-row class="mb-5" v-else>
                                <v-col cols="12" sm="6" md="4" v-for="(record, i) in records" :key="i">
                                    <v-card :elevation="record.current_term == true ? '2' : '1'" :color="record.current_term == true ? '#f2fff3' : '#f0f0f0'">
                                        <v-card-title>
                                            {{record.term}} Term &nbsp;
                                            <v-icon v-if="record.current_term == true" small color="green darken-2"
                                            >mdi-checkbox-marked</v-icon>
                                            <v-icon v-else x-small color="red darken-2"
                                            >mdi-checkbox-intermediate</v-icon>
                                            </v-card-title>
                                        <v-card-subtitle>{{record.session.session}} | {{record.schooldays}} Days</v-card-subtitle>
                                        <v-card-text>Next resumption date is <strong>{{record.resumptiondate}}</strong></v-card-text>
                                        <v-card-actions>
                                            <v-btn @click="editRecord(record)" fab dark x-small
                                            ><v-icon dark> mdi-pencil </v-icon>
                                            </v-btn>
                                            <v-btn v-if="record.total_students_with_results == 0 && record.current_term == false" @click="deleteRecord(record)" fab color="error" x-small
                                            ><v-icon dark> mdi-trash-can-outline </v-icon>
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>

                            <v-btn tile text color="success" @click="reset">
                                Add New Term
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HeaderVue from '../../components/Header.vue'
import axios from 'axios';
import InfoLoader2 from '../../components/InfoLoader2.vue';
  export default {
    name: 'Terms',
    components: {
        HeaderVue,
        InfoLoader2
    },
    mounted() {
       if (!this.authToken) {
           this.$router.push('login')
       }
       this.getSessions()
       this.getRecords()
    },
    data: () => ({
        authToken:sessionStorage.getItem('authToken') ?? null,
        school:localStorage.getItem('school') ? JSON.parse(localStorage.getItem('school')) : null,
        newform: false,
        editBtn: false,
        form:{},
        records:[],
        sessions:[],
        terms: [
            {id:'1', name:'1st Term'},
            {id:'2', name:'2nd Term'},
            {id:'3', name:'3rd Term'},
        ],
        infos:[
            {content: 'You can always set a new term whenever your school moves to a NEW term by clicking the Add New Term button. However you should only select a new Session ONLY when your school has moved to a new session.'},
            {content: 'Switching Sessions when you are still in the same session can have some unpredictable consequence.'},
            {content: 'Switching to a New session removes your students from their current class and you will have to Promote them to a new class before they become visible again.'},
            {content: 'You DO NOT have to change a past term to a current term if your aim is to view results sheets for that past term. You can simply do that by going to the Menu and clicking on Result Sheets.'},
        ],
        menu2:false,
        valid: true,
        fetchingContent:true
    }),
    metaInfo: {
        meta: [
            { name: 'viewport', content: 'width=device-width,initial-scale=1.0' }
        ]
    },
    methods: {
        reset () {
            this.newform = true
            this.form = {}
        },
        getSessions () {
            axios.get(process.env.VUE_APP_BASEURL+'/sessions?school_id='+this.school.school_id,
            {
                headers: 
                    {
                        'Authorization': 'Bearer '+this.authToken
                    }
            })
            .then((response) => {
                this.sessions =  response.data.data            
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
            });
        },
        getRecords () {
            axios.get(process.env.VUE_APP_BASEURL+'/parameters?school_id='+this.school.school_id,
            {
                headers: 
                    {
                        'Authorization': 'Bearer '+this.authToken
                    }
            })
            .then((response) => {
                this.records =  response.data.data  
                this.fetchingContent = false;          
            })
            .catch((error) => {
                if (error.response.status == 401) {
                    localStorage.removeItem('authToken')
                }
                this.$toast.error(error.response ? error.response.data.message : 'APP is temporarily unavailable. Wait or Contact us!', {
                    timeout: 5000
                });
            })
            .finally(() => {
            });
        },
        postRecord () {
            let validation = this.$refs.form.validate()
            if(!validation) {
                return validation
            }
            let loader = this.$loading.show()
            axios.post(process.env.VUE_APP_BASEURL+'/parameters?school_id='+this.school.school_id,this.form,
            {
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken
                }
            })
            .then((response) => {
                this.$toast.success(response.data.message, {
                    timeout: 5000
                }); 
                this.getRecords()
                this.newform = false             
            })
            .catch((error) => {
                this.$toast.error(error.response ? error.response.data.message : 'Network Error, Please try again few minutes', {
                    timeout: 5000
                });
            })
            .finally(() => {
                loader.hide()
            });
        },
        editRecord (record){
            this.newform = true
            this.form.id = record.parameter_id
            this.form.session_id = record.session.session_id
            this.form.term = record.raw_untransformed.term
            this.form.schooldays = record.schooldays
            this.form.generalupdate = record.generalupdate
            this.form.current = record.current_term
            this.form.resumptiondate = record.raw_untransformed.resumptiondate
            this.editBtn = true
        },
        updateRecord () {
            let validation = this.$refs.form.validate()
            if(!validation) {
                return validation
            }
            let loader = this.$loading.show()
            axios.put(process.env.VUE_APP_BASEURL+'/parameters/'+this.form.id+'?school_id='+this.school.school_id,this.form,
            {
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken
                }
            })
            .then((response) => {
                this.$toast.success(response.data.message, {
                    timeout: 5000
                }); 
                this.getRecords()
                this.editBtn = false             
                this.newform = false             
            })
            .catch((error) => {
                this.$toast.error(error.response ? error.response.data.message : 'Network Error, Please try again few minutes', {
                    timeout: 5000
                });
            })
            .finally(() => {
                loader.hide()
            });
        },
        deleteRecord (record) {
            if(confirm("Do you really want to delete?")){
                let loader = this.$loading.show()
                axios.delete(process.env.VUE_APP_BASEURL+'/parameters/'+record.parameter_id+'?school_id='+this.school.school_id+'&confirm=DELETE',{
                    headers: 
                    {
                        'Authorization': 'Bearer '+this.authToken
                    }
                })
                .then(response => {
                     this.$toast.success(response.data.message, {
                        timeout: 5000
                    }); 
                    this.getRecords()
                })
                .catch(error => {
                    this.$toast.error(error.response ? error.response.data.message : 'Network Error, Please try again few minutes', {
                    timeout: 5000
                    });
                })
                .finally(() => {
                    loader.hide()
                });
            }
        }
    },
  }
</script>
